import {
  CaretDownOutlined,
  ContactsOutlined,
  CopyOutlined,
  DoubleRightOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  ProfileOutlined,
  TrophyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Menu,
  Popover,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Bell,
  Box,
  DollarSign,
  FileText,
  Globe,
  Home,
  PieChart,
  Rss,
  Settings,
  Shield,
  UserCheck,
  UserPlus,
  Users,
  UserX,
  Zap,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LANG_KEY_MAPPING, LANGUAGES } from "../../constants";
import { useBoundStore } from "../../store/useBoundStore";
import "../../theme.dark.less";
import "../../theme.light.less";
import { userInfo } from "../../utils";
import "./Header.css";

const { Paragraph } = Typography;

const Header = ({ history, notifications }) => {
  const [theme, setTheme] = useState("light");
  const [visible, setVisible] = useState(false);
  const [visibleNoti, setVisibleNoti] = useState(false);

  const lang = useBoundStore((state) => state.lang);
  const updateLanguage = useBoundStore((state) => state.updateLanguage);
  const { t, i18n } = useTranslation();

  const username = userInfo?.user?.fullname;
  const isAccountant = userInfo?.user?.role.includes("accountant");
  const isCollaborator = userInfo?.user?.isCollaborator === true;
  const isOnlyClass =
    userInfo?.user?.role.includes("only_class") ||
    userInfo?.user?.role.includes("cskh");
  const isNotAdmin = !userInfo?.user?.role.includes("admin");
  const isOnlySetClass = userInfo?.user?.role.includes("only_set_class");
  const isUnkown1 = userInfo?.user?.role.includes("unknown_1");
  const isSalesMn = userInfo?.user?.role.includes("sales_mn");
  const isHr = userInfo?.user?.role.includes("hr");
  const isSales = userInfo?.user?.role.includes("sales");

  useEffect(() => {
    if (theme === "light") {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    } else {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    }
  }, [theme]);

  const changeTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    history.push("/login");
    window.location.reload();
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleChangeLang = (key) => {
    updateLanguage(key);
    i18n.changeLanguage(key);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile">
          <ProfileOutlined /> {t("accountSettings")}
        </Link>
      </Menu.Item>
      <Menu.Item onClick={handleLogout}>
        <LogoutOutlined /> {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const mobileMenu = (
    <Menu
      onClick={() => setVisible(false)}
      style={{ float: "right", marginRight: "15px" }}
    >
      <Menu.Item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hello">
          <Avatar icon={<UserOutlined />} style={{ marginRight: "5px" }} />
          <span className="user_name">{username ? username : ""}</span>
        </div>
      </Menu.Item>
      {/* <Menu.Item>
        {t("themes")}{" "}
        <SwitchTheme
          onClick={changeTheme}
          checkedChildren={
            <img
              src='/sun.svg'
              style={{ marginBottom: "3px", height: "16px" }}
              alt='Dark'
            />
          }
          unCheckedChildren={
            <img
              src='/moon.svg'
              style={{ marginBottom: "3px", height: "16px" }}
              alt='Light'
            />
          }
          defaultChecked
        />
      </Menu.Item> */}
      <Menu.Item
        key="home"
        icon={<Home size={14} color="rgba(28, 29, 33, 0.88)" />}
        className="ant-menu-submenu-title"
      >
        <Link to="/">{t("home").toUpperCase()}</Link>
      </Menu.Item>
      <Menu.SubMenu
        key="sub-data-records"
        icon={<Box size={14} color="rgba(28, 29, 33, 0.88)" />}
        title={t("crm").toUpperCase()}
      >
        <Menu.SubMenu
          title={t("customers").toUpperCase()}
          key="sub-data-customers"
          icon={<CopyOutlined />}
        >
          <Menu.Item
            key="import"
            icon={<UserPlus size={14} color="rgba(28, 29, 33, 0.88)" />}
            hidden={isAccountant || isOnlyClass || isOnlySetClass}
          >
            <Link to="/import">{t("dataEntry")}</Link>
          </Menu.Item>
          <Menu.Item
            key="students-unRegistered"
            icon={<UserX size={14} color="rgba(28, 29, 33, 0.88)" />}
            hidden={isOnlyClass || isOnlySetClass || isUnkown1}
          >
            <Link
              to="/students?filter=unRegistered"
              style={{ fontWeight: 600 }}
            >
              {t("unregisteredCustomers")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="chuaxeplop"
            icon={<Users size={14} color="rgba(28, 29, 33, 0.88)" />}
            hidden={isOnlyClass || isOnlySetClass || isUnkown1}
          >
            <Link
              to="/students?filter=chua-xep-lop"
              style={{ fontWeight: 600 }}
            >
              {t("CHƯA XẾP LỚP")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="students-reservation"
            icon={<Users size={14} color="rgba(28, 29, 33, 0.88)" />}
            hidden={isOnlyClass || isOnlySetClass || isUnkown1}
          >
            <Link to="/students?filter=reservation" style={{ fontWeight: 600 }}>
              {t("reserved")}
            </Link>
          </Menu.Item>
          {userInfo?.user?.username !== "TV0048" && (
            <>
              <Menu.Item
                key="students-registered"
                icon={<UserCheck size={14} color="rgba(28, 29, 33, 0.88)" />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to="/students?filter=registered">
                  {t("registeredCustomers")}
                </Link>
              </Menu.Item>
              {/* <Menu.Item
                key='vip-business'
                icon={<UserCheck size={14} color='rgba(28, 29, 33, 0.88)' />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to='/vip-business'>{t("vipBusiness")}</Link>
              </Menu.Item> */}
              <Menu.Item
                key="students-studying"
                icon={<Users size={14} color="rgba(28, 29, 33, 0.88)" />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to="/students?filter=studying">
                  {t("enrolledCustomers")}
                </Link>
              </Menu.Item>
              <Menu.Item
                key="students-L1-to-L2"
                icon={<Users size={14} color="rgba(28, 29, 33, 0.88)" />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to="/students?filter=L1L2">{t("L1L2")}</Link>
              </Menu.Item>
              <Menu.Item
                key="students-L2-to-L3"
                icon={<Users size={14} color="rgba(28, 29, 33, 0.88)" />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to="/students?filter=L2L3">{t("L2L3")}</Link>
              </Menu.Item>
              {/* <Menu.Item
                key='students-T+1'
                 icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to='/students?filter=T1'>{t('T1')}</Link>
              </Menu.Item> */}
              <Menu.Item
                key="students-drop"
                icon={<Users size={14} color="rgba(28, 29, 33, 0.88)" />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to="/students?filter=drop">{t("dropped")}</Link>
              </Menu.Item>
              {/* <Menu.Item
                key='students-T+15'
                 icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to='/students?filter=T15'>{t('T15')}</Link>
              </Menu.Item>
              <Menu.Item
                key='students-T+30'
                 icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to='/students?filter=T30'>{t('T30')}</Link>
              </Menu.Item>
              <Menu.Item
                key='students-T+90'
                 icon={<Users size={14} color='rgba(28, 29, 33, 0.88)' />}
                hidden={isOnlyClass || isOnlySetClass || isUnkown1}
              >
                <Link to='/students?filter=T90'>{t('T90')}</Link>
              </Menu.Item> */}
            </>
          )}
        </Menu.SubMenu>
        <Menu.Item
          key="classes"
          icon={<FileText size={14} color="rgba(28, 29, 33, 0.88)" />}
          hidden={
            isAccountant ||
            isCollaborator ||
            userInfo?.user?.username === "TV0048"
          }
        >
          <Link to="/classes">{t("classes")}</Link>
        </Menu.Item>
        <Menu.Item
          key="finance"
          icon={<DollarSign size={14} color="rgba(28, 29, 33, 0.88)" />}
          hidden={
            isAccountant ||
            isCollaborator ||
            isOnlyClass ||
            isOnlySetClass ||
            isUnkown1 ||
            userInfo?.user?.username === "TV0048"
          }
        >
          <Link to="/finance">{t("manageFinances")} </Link>
        </Menu.Item>
        <Menu.Item
          key="ranking"
          icon={<TrophyOutlined />}
          hidden={isNotAdmin && !isSales && !isSalesMn}
        >
          <Link to="/ranking">{t("ranking")} </Link>
        </Menu.Item>
        <Menu.Item
          key="statistics"
          icon={<PieChart size={14} color="rgba(28, 29, 33, 0.88)" />}
          hidden={
            (isNotAdmin &&
              !isSalesMn &&
              userInfo?.user?.username !== "TVTS4" &&
              userInfo?.user?.username !== "TV0048") ||
            isOnlySetClass ||
            isUnkown1
          }
        >
          <Link to="/statistics">{t("statistics")}</Link>
        </Menu.Item>
        <Menu.Item
          key="requests_sent"
          icon={<Zap size={14} color="rgba(28, 29, 33, 0.88)" />}
          hidden={userInfo?.user?.username === "TV0048"}
        >
          <Link to="/requests/me">{t("requests")}</Link>
        </Menu.Item>
      </Menu.SubMenu>
      {(!isNotAdmin ||
        userInfo?.user?.role.includes("dev") ||
        isHr ||
        isUnkown1 ||
        isOnlySetClass ||
        userInfo?.user?.username === "TVTS4" ||
        userInfo?.user?.username === "TV000167" ||
        isSalesMn) && (
        <Menu.SubMenu
          key="sub2"
          icon={<Shield size={14} color="rgba(28, 29, 33, 0.88)" />}
          title="ADMIN"
        >
          <Menu.Item hidden={isSalesMn || isHr}>
            <Link to="/classes/setting">
              <ContactsOutlined /> {t("manageClasses")}
            </Link>
          </Menu.Item>
          <Menu.Item hidden={isOnlySetClass || isUnkown1 || isHr}>
            <Link to="/cosultants/setting">
              <Users size={14} color="rgba(28, 29, 33, 0.88)" />{" "}
              {t("manageConsultants")}
            </Link>
          </Menu.Item>
          <Menu.Item
            hidden={
              isOnlySetClass ||
              userInfo?.user?.username === "TVTS4" ||
              userInfo?.user?.username === "TV000167" ||
              isUnkown1 ||
              isSalesMn ||
              isHr
            }
          >
            <Link to="/notifications/setting">
              <Bell size={14} color="rgba(28, 29, 33, 0.88)" />{" "}
              {t("manageNotifications")}
            </Link>
          </Menu.Item>
          <Menu.Item hidden={isNotAdmin}>
            <Link to="/manage/requests">
              <Zap size={14} color="rgba(28, 29, 33, 0.88)" />{" "}
              {t("manageRequests")}
            </Link>
          </Menu.Item>
          <Menu.Item hidden={isNotAdmin && !isSalesMn}>
            <Link to="/manage/revenue">
              <DollarSign size={14} color="rgba(28, 29, 33, 0.88)" />{" "}
              {t("revenueManagement")}
            </Link>
          </Menu.Item>
          <Menu.Item hidden={isNotAdmin && !isUnkown1 && !isHr}>
            <Link to="/users">
              <Users size={14} color="rgba(28, 29, 33, 0.88)" />{" "}
              {t("humanResourceManagement")}
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {(!isNotAdmin || userInfo?.user?.role.includes("dev")) && (
        <Menu.SubMenu
          key="sub3"
          icon={<Settings size={14} color="rgba(28, 29, 33, 0.88)" />}
          title={`${t("settings").toUpperCase()}`}
        >
          <Menu.Item key="pancake-config">
            <Link to="/system-configs/pancake">
              <img
                src="/pancake-logo.svg"
                width="100"
                height="20"
                alt="Pancake"
              />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="channel-config"
            icon={<Globe size={14} color="rgba(28, 29, 33, 0.88)" />}
          >
            <Link to="/system-configs">{t("general")}</Link>
          </Menu.Item>
          <Menu.Item
            key="extras"
            icon={<Rss size={14} color="rgba(28, 29, 33, 0.88)" />}
          >
            <Link to="/extras">{t("extras")}</Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}
      <Menu.Item>
        <Link to="/profile">
          <ProfileOutlined /> {t("accountSettings")}
        </Link>
      </Menu.Item>

      <Menu.Item onClick={handleLogout}>
        <LogoutOutlined /> {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const noti = (
    <>
      {notifications?.length > 0 ? (
        <>
          {notifications.slice(0, 3).map((noti) => (
            <Link
              to={`/notifications/${noti._id}`}
              onClick={() => setVisibleNoti(false)}
            >
              <div className="noti-menu-item">
                <Avatar size="large" src={noti.user.avatar} />
                <div>
                  <Paragraph
                    className="noti-item-text"
                    key={noti._id}
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                  >
                    {noti.title}{" "}
                    {noti.description ? `: ${noti.description}` : ""}
                  </Paragraph>
                  <div className="noti-item-text time noti-sub-text">
                    {dayjs(noti.createdAt).fromNow()}
                    <span>
                      <span> · </span>
                      {noti.user.fullname}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </>
      ) : (
        <p>{t("noNotifications")}</p>
      )}
      {notifications?.length > 0 ? (
        <Link to="/" style={{ marginLeft: "3px" }}>
          {t("seeAll")} <DoubleRightOutlined />
        </Link>
      ) : null}
    </>
  );

  const notiTitle = (
    <p style={{ margin: "0", fontSize: "15px" }}>{t("notifications")}</p>
  );

  return (
    <div className="header">
      <div className="logo">
        {userInfo && (
          <>
            <Link to="/" className="shine">
              <img src="/favicon.png" height="45" width="" alt="Logo" />
            </Link>
          </>
        )}
        {!userInfo && (
          <div className="shine">
            <img src="/favicon.png" height="45" width="" alt="Logo" />
          </div>
        )}
        <div className="text">CRM</div>
      </div>

      <div className="user-box">
        {username ? (
          <>
            <div className="language">
              <Dropdown
                placement="bottomRight"
                menu={{
                  items: LANGUAGES.map((option) => ({
                    key: option.code,
                    label: option.label,
                    icon: <img src={option.img} alt={option.code} width={20} />,
                    // disabled:
                    //   option.code === 'en' &&
                    //   !userInfo?.user.role.includes('admin'),
                  })),
                  selectable: true,
                  defaultSelectedKeys: [lang || "vi"],
                  onClick: ({ key }) => handleChangeLang(key),
                }}
                trigger={["click"]}
              >
                <Button style={{ marginRight: 10 }}>
                  <Space
                    style={{
                      paddingInline: 5,
                    }}
                  >
                    <img src={`/${lang}-flag.png`} alt={lang} width={20} />
                    {LANG_KEY_MAPPING[lang]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
            <div
              className="noti"
              style={{
                marginLeft: 6,
              }}
            >
              <Popover
                open={visibleNoti}
                placement="bottom"
                title={notiTitle}
                content={noti}
                onOpenChange={(visible) => setVisibleNoti(visible)}
              >
                <Badge count={notifications && notifications.length}>
                  <Bell
                    size={20}
                    color="rgba(28, 29, 33, 0.88)"
                    className="noti-icon"
                  />
                </Badge>
              </Popover>
            </div>
            <div className="user-menu">
              {/* <SwitchTheme
                onClick={changeTheme}
                checkedChildren={
                  <img
                    src='/sun.svg'
                    style={{ marginBottom: '3px', height: '16px' }}
                    alt='Dark'
                  />
                }
                unCheckedChildren={
                  <img
                    src='/moon.svg'
                    style={{ marginBottom: '3px', height: '16px' }}
                    alt='Light'
                  />
                }
                defaultChecked
              /> */}
              <Dropdown overlay={menu} placement="bottomRight">
                <p className="hello">
                  <Avatar
                    size="large"
                    src={userInfo?.user?.avatar || ""}
                    style={{ marginRight: 5 }}
                  />
                  <CaretDownOutlined />
                </p>
              </Dropdown>
            </div>
            <div className="user-mobile-menu">
              <Button type="text" onClick={showDrawer}>
                <p className="hello">
                  <MenuOutlined />
                </p>
              </Button>
              <Drawer
                width={250}
                title="Menu"
                placement="right"
                onClose={onClose}
                open={visible}
              >
                {mobileMenu}
              </Drawer>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
