import {
  CheckCircleOutlined,
  ControlOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Alert,
  AutoComplete,
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { Confirm } from "react-st-modal";
import { useWindowSize } from "react-use";
import { FACILITY_MAPPING } from "../../constants";
import { useOptions } from "../../hooks/useOptions";
import { getRegisteredRecords } from "../../resources/records";
import { useBoundStore } from "../../store/useBoundStore";
import {
  FILTER_QUERY_MAPPING,
  LEVEL_ENUM,
  config,
  getFilterTags,
  getTableRowClassNameRegisterClassTable,
  getTableRowTooltipRegisterClassTable,
  isSafari,
  userInfo,
  validateEmail,
} from "../../utils";
import emptyCell from "../../utils/emptyCell";
import locations from "../../utils/locations";
import { statusRecord } from "../../utils/status";
import * as studyStatusEnum from "../../utils/studyStatus";
import BillDetails from "../BillDetails";
import Loader from "../Common/Loader/Loader";
import Ripple from "../Common/Ripple";
import "../Common/Table.css";
import Tag from "../Common/Tag";
import UpdateForm from "../Common/UpdateForm";
import ExportExcel from "../ExportExcel/ExportExcel";
import { LevelStudyStatusList } from "../LevelStudyStatusList/LevelStudyStatusList";
import RandomColorBox from "../RandomColorBox/RandomColorBox";
import SendEmailDrawer from "./SendEmailDrawer";
dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text } = Typography;

const CustomRow = ({ tooltip, ...restProps }) => {
  return (
    <Tooltip title={tooltip} placement="left">
      <tr {...restProps} />
    </Tooltip>
  );
};

const RegisterTable = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { width } = useWindowSize();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataSheets, setDataSheets] = useState([]);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loadingMailBill, setLoadingMailBill] = useState(false);
  const [isSentBill, setIsSentBill] = useState(0);
  const [openEmailDrawer, setOpenEmailDrawer] = useState(false);

  const [willChangePage, setWillChangePage] = useState(false);

  console.log("updateItem", updateItem);

  const filters = useBoundStore((state) => state.filters.registerTable);
  const {
    name,
    expectedClass,
    startDate,
    endDate,
    channel,
    cosultant,
    yearOfBirth,
    address,
    phone,
    studyStatus,
    status,
    source,
    tuitionStatus,
    cusomerType,
    level,
    facility,
    email,
    page,
    limit,
  } = filters;

  const updateRegisterTableFilters = useBoundStore(
    (state) => state.updateRegisterTableFilters
  );
  const clearRegisterTableFilter = useBoundStore(
    (state) => state.clearRegisterTableFilter
  );
  const clearAllRegisterTableFilters = useBoundStore(
    (state) => state.clearAllRegisterTableFilters
  );
  const exportOptions = useBoundStore((state) => state.exportOptions);
  const updateExportOptions = useBoundStore(
    (state) => state.updateExportOptions
  );

  const search = useLocation().search;
  const pathFilter = new URLSearchParams(search).get("filter");

  const ranking = useBoundStore((state) => state.ranking);

  const { data: channels } = useOptions("channels", { initialData: [] });
  const { data: classes } = useOptions("classes", { initialData: [] });
  const { data: cosultants } = useOptions("cosultants", { initialData: [] });
  const { data: users } = useOptions("users", { initialData: [] });

  const teachers = useMemo(() => {
    return users?.filter((user) => user.role?.includes("teacher"));
  }, [users]);
  const assistants = useMemo(() => {
    return users?.filter((user) => user.role?.includes("assistant"));
  }, [users]);

  const query = useMemo(
    () => ({
      name,
      expected_class: expectedClass,
      start_date: startDate,
      end_date: endDate,
      channel,
      cosultant,
      year_of_birthday: yearOfBirth,
      address,
      phone,
      study_status:
        studyStatus || FILTER_QUERY_MAPPING[pathFilter]?.study_status,
      status,
      source,
      tuition_status: tuitionStatus,
      is_register: pathFilter !== "unRegistered",
      level,
      facility,
      customer_type:
        cusomerType || FILTER_QUERY_MAPPING[pathFilter]?.customer_type,
      t_type: FILTER_QUERY_MAPPING[pathFilter]?.t_type,
      email,
      page,
      limit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, pathFilter]
  );

  const {
    data: dataSource,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery(
    ["getDataSource/registered", updateTable, pathFilter],
    () => getRegisteredRecords(query),
    {
      initialData: null,
      refetchInterval: !willChangePage ? 60000 : false,
      onSuccess: () => {
        setWillChangePage(false);
      },
      onError: () => {
        setWillChangePage(false);
      },
    }
  );

  const fetchExcel = async () => {
    try {
      setLoadingExcel(true);

      let dataSourceExport = [];
      const exportPage = exportOptions.page;
      if (exportPage === "2") {
        const { data } = await getRegisteredRecords({ ...query, limit: "" });
        dataSourceExport = data;
      } else if (dataSource?.data?.length) {
        dataSourceExport = [...dataSource.data];
      }

      if (dataSourceExport.length) {
        let dataSets = [];
        dataSourceExport.forEach((record) => {
          let dataSet = {};
          dataSet.no = record.no;
          dataSet.code = record.code;
          dataSet.createdAt = dayjs(record.createdAt).format("DD/MM/YYYY");
          dataSet.phone = record.phone;
          dataSet.name = record.name;
          dataSet.year_of_birthday = record.year_of_birthday;
          dataSet.sex = record.sex;
          dataSet.email = record.email;
          dataSet.address = record.address;
          dataSet.joined_class = record.joined_class;
          dataSet.study_status = record.study_status;
          dataSet.isKhachLe = record.isKhachLe;
          dataSet.isKhachCombo = record.isKhachCombo;
          dataSet.isKhachHocLen = record.isKhachHocLen;
          dataSet.isL1L2 = record.isL1L2;
          dataSet.isL2L3 = record.isL2L3;
          dataSet.paid_tuition_1 = record.paid_tuition_1;
          dataSet.paid_tuition_2 = record.paid_tuition_2;
          dataSet.unpaid_tuition = record.unpaid_tuition;
          dataSet.discount = record.discount;
          dataSet.act_received = record.act_received;
          dataSet.transfer_method_1 = record.transfer_method_1;
          dataSet.transfer_method_2 = record.transfer_method_2;
          dataSet.transfer_date_1 = record.transfer_date_1
            ? dayjs(record.transfer_date_1).format("DD/MM/YYYY")
            : "";
          dataSet.transfer_info_1 = record.transfer_info_1;
          dataSet.transfer_date_2 = record.transfer_date_2
            ? dayjs(record.transfer_date_2).format("DD/MM/YYYY")
            : "";
          dataSet.transfer_info_2 = record.transfer_info_2;
          dataSet.tuition_status = record.tuition_status;
          dataSet.cosultant = record.cosultant;
          dataSet.confirm_mail = record.confirm_mail;
          dataSet.status = record.status;
          dataSet.callback = record.callback
            ? dayjs(record.callback).format("HH:mm, DD/MM/YYYY")
            : "";
          dataSet.level = record.level;
          dataSet.occupation = record.occupation;
          dataSet.purpose = record.purpose;
          dataSet.facility = record.facility;
          dataSet.note = record.note;
          dataSet.note_1 = record.note_1;

          dataSets.push(dataSet);
        });

        const columns = [
          {
            label: t("no"),
            value: "no",
          },
          {
            label: t("orderNumber"),
            value: "code",
          },
          {
            label: "Ngày nhập",
            value: "createdAt",
          },
          { label: t("recordPhone"), value: "phone" },
          { label: t("recordName"), value: "name" },
          { label: t("recordDateOfBirthday"), value: "year_of_birthday" },
          { label: t("recordSex"), value: "sex" },
          { label: t("recordEmail"), value: "email" },
          { label: t("recordAddress"), value: "address" },
          { label: t("class"), value: "joined_class" },
          { label: t("studyStatus"), value: "study_status" },
          { label: t("standardCustomer"), value: "isKhachLe" },
          { label: t("comboCustomer"), value: "isKhachCombo" },
          { label: t("levelUpCustomer"), value: "isKhachHocLen" },
          { label: t("L1L2"), value: "isL1L2" },
          { label: t("L2L3"), value: "isL2L3" },
          { label: t("paid1"), value: "paid_tuition_1" },
          { label: t("paid2"), value: "paid_tuition_2" },
          { label: t("unPaid"), value: "unpaid_tuition" },
          { label: t("discount"), value: "discount" },
          { label: t("received"), value: "act_received" },
          { label: t("paymentMethod1"), value: "transfer_method_1" },
          { label: t("paidDate1"), value: "transfer_date_1" },
          { label: t("paymentContent1"), value: "transfer_info_1" },
          { label: t("paymentMethod2"), value: "transfer_method_2" },
          { label: t("paidDate2"), value: "transfer_date_2" },
          { label: t("paymentContent2"), value: "transfer_info_2" },
          { label: t("tuitionStatus"), value: "tuition_status" },
          { label: t("recordConsultant"), value: "cosultant" },
          { label: t("confirmationMail"), value: "confirm_mail" },
          { label: t("recordStatus"), value: "status" },
          { label: t("recordCallback"), value: "callback" },
          { label: t("recordClassification"), value: "level" },
          { label: t("recordOccupation"), value: "occupation" },
          { label: t("recordPurpose"), value: "purpose" },
          { label: t("recordFacility"), value: "facility" },
          { label: t("recordNote1"), value: "note" },
          { label: t("recordNote2"), value: "note_1" },
        ];

        const sheet = {
          sheetName: `Học viên đăng ký_${new Date().getTime()}`,
          sheetDataSets: dataSets,
          sheetColumns: columns,
        };

        setDataSheets([sheet]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExcel(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    fetchExcel(exportOptions.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportOptions.page, dataSource]);

  const onUpdate = (values) => {
    const updateRecord = async () => {
      const userId = users.find(
        (user) => user.fullname === values.cosultant
      )?._id;

      if (!!userId) {
        values.user = userId;
      }

      values.updatedBy = userInfo?.user?.fullname;

      const isTuitionCompleted =
        Math.sign(
          parseInt(values.unpaid_tuition?.toString().replaceAll(".", ""))
        ) <= 0 || !values.unpaid_tuition;
      values.tuition_status = isTuitionCompleted ? "Đã hoàn thành" : "Còn nợ";

      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/records/${updateItem._id}`,
        values,
        config
      );

      setLoadingUpdate(false);
      setUpdateTable(!updateTable);
      setVisibleUpdate(false);

      message.success("Cập nhật thành công");
    };

    updateRecord();
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDelete = async () => {
    setLoading(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/records/${id}`,
        config
      );

      setUpdateTable(!updateTable);
    };

    const result = await Confirm(t("confirmDelete"));

    if (result) {
      selectedRowKeys.map((id) => {
        deleteById(id);
      });

      setLoading(false);
      setSelectedRowKeys([]);

      message.success(t("deletedSuccessfully"));
    } else {
      setLoading(false);
      setSelectedRowKeys([]);
    }
  };

  const handleCopy = async (record) => {
    try {
      if (!record) return;

      setUpdateItem(record);

      const result = await Confirm(
        "Một bản ghi mới sẽ được tạo với thông tin cá nhân và các thông tin cơ bản khác, tiếp tục?"
      );

      if (result) {
        message.success("Đang tạo bản sao...");
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/records/${record._id}/copy`,
          {},
          config
        );

        if (data?.success) {
          message.success("Nhân bản thành công");
          refetch();
        }
      }
    } catch (error) {
      message.error("Nhân bản thất bại");
      console.log(error);
    } finally {
    }
  };

  const columns = [
    {
      title: t("action"),
      dataIndex: "",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <Tooltip
            placement="rightBottom"
            title={`${t("recordCreatedBy")} ${
              record.createdBy || "THALIC EDU"
            }. ${t("recordLastModified")}: ${new Date(
              record.updatedAt
            ).toLocaleString()} ${t("by")}: ${
              record.updatedBy || record.createdBy || "THALIC EDU"
            }`}
          >
            <Button
              disabled={userInfo?.user.role.includes("accountant")}
              size="small"
              icon={<EditOutlined />}
              onClick={() => {
                setVisibleUpdate(true);
                setUpdateItem(record);
              }}
            >
              <Ripple color="#ffcd0f" />
            </Button>
          </Tooltip>
          <Tooltip placement="rightBottom" title={t("clone")}>
            <Button
              size="small"
              style={{
                marginLeft: 8,
              }}
              icon={<CopyOutlined />}
              onClick={() => handleCopy(record)}
            >
              <Ripple color="#ffcd0f" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t("no"),
      dataIndex: "no",
      fixed: "left",
      width: 50,
    },
    {
      title: t("recordName"),
      dataIndex: "name",
      fixed: width >= 768 ? "left" : "",
      render: (_, record) => (
        <Button
          type="text"
          style={{ width: "100%" }}
          onClick={() => {
            setVisible(true);
            setUpdateItem(record);
            setIsSentBill(record.isSentBill);
          }}
        >
          <div className="flex items-center">
            {record.next_contact &&
            dayjs().isBetween(
              dayjs(record.next_contact).subtract(30, "minute"),
              dayjs(record.next_contact).subtract(1, "minute"),
              "minute",
              "[]"
            ) ? (
              <img
                src="/phone-call.png"
                alt="phone-call"
                width={20}
                style={{
                  marginRight: 5,
                }}
                className="blink"
              />
            ) : null}
            <span style={{ color: "#2ecc71", fontWeight: "500" }}>
              {emptyCell(record.name)}
            </span>
          </div>
        </Button>
      ),
    },
    {
      title: t("orderNumber"),
      dataIndex: "code",
      width: 50,
      render: (_, record) => (
        <Button
          type="text"
          style={{ width: "100%" }}
          onClick={() => {
            setVisible(true);
            setUpdateItem(record);
            setIsSentBill(record.isSentBill);
          }}
        >
          <span style={{ color: "#2ecc71", fontWeight: "500" }}>
            {record.code}
          </span>
        </Button>
      ),
    },
    {
      title: t("receipt"),
      dataIndex: "",
      width: 50,
      render: (_, record) => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          {record.isSentBill ? (
            <Tag
              icon={<CheckCircleOutlined />}
              style={{
                color: "#2ecc71",
                borderColor: "#2ecc71",
                fontWeight: 600,
              }}
            >
              {t("sent")}
            </Tag>
          ) : (
            <Tag
              icon={<ExclamationCircleOutlined />}
              style={{
                color: "#f00",
                borderColor: "#f00",
                fontWeight: 600,
              }}
            >
              {t("notSent")}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: t("recordPhone"),
      dataIndex: "phone",
      render: (_, record) =>
        emptyCell(record.phone ? record.phone.slice(0, -3) + "***" : ""),
    },
    {
      title: t("recordInputDate"),
      dataIndex: "createdAt",
      width: 120,
      render: (_, record) => dayjs(record.createdAt).format("DD/MM/YYYY"),
    },
    {
      title: t("recordLastContact"),
      dataIndex: "last_contact",
      render: (_, record) =>
        record.last_contact
          ? dayjs(record.last_contact).format("HH:mm, DD/MM/YYYY")
          : "",
      width: 120,
    },
    {
      title: t("recordNextContact"),
      dataIndex: "next_contact",
      render: (_, record) =>
        record.next_contact
          ? dayjs(record.next_contact).format("HH:mm, DD/MM/YYYY")
          : "",
      width: 120,
    },
    {
      title: t("recordDateOfBirthday"),
      dataIndex: "year_of_birthday",
      width: 75,
    },
    {
      title: t("recordSex"),
      dataIndex: "sex",
      width: 75,
    },
    {
      title: t("recordEmail"),
      dataIndex: "email",
    },
    {
      title: t("recordAddress"),
      dataIndex: "address",
    },
    {
      title: t("class"),
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <>
          {record.sub_class?._id && record.class?._id ? (
            <Link
              to={`/records?sub_class_id=${record.sub_class?._id}&class_id=${record.class?._id}`}
            >
              <Tag style={{ margin: 0 }} color="#16c784">
                {emptyCell(record.sub_class?.class_name)}
              </Tag>
            </Link>
          ) : (
            <>{emptyCell(false)}</>
          )}
        </>
      ),
    },
    {
      title: t("studyStatus"),
      dataIndex: "study_status",
      width: 100,
      render: (_, record) => emptyCell(record.study_status),
    },
    {
      title: t("standardCustomer"),
      dataIndex: "isKhachLe",
      render: (_, record) => (record.isKhachLe ? "️✔️" : ""),
      width: 75,
    },
    {
      title: t("comboCustomer"),
      dataIndex: "isKhachCombo",
      render: (_, record) => (record.isKhachCombo ? "️✔️" : ""),
      width: 100,
    },
    {
      title: t("levelUpCustomer"),
      dataIndex: "isKhachHocLen",
      render: (_, record) => (record.isKhachHocLen ? "️✔️" : ""),
      width: 110,
    },
    {
      title: t("L1L2"),
      dataIndex: "isL1L2",
      render: (_, record) => (record.isL1L2 ? "️✔️" : ""),
      width: 110,
    },
    {
      title: t("L2L3"),
      dataIndex: "isL2L3",
      render: (_, record) => (record.isL2L3 ? "️✔️" : ""),
      width: 110,
    },
    {
      title: t("paid1"),
      dataIndex: "paid_tuition_1",
      width: 110,
      render: (_, record) => emptyCell(record.paid_tuition_1),
    },
    {
      title: t("paid2"),
      dataIndex: "paid_tuition_2",
      width: 110,
    },
    { title: t("unPaid"), dataIndex: "unpaid_tuition", width: 110 },
    { title: t("discount"), dataIndex: "discount", width: 85 },
    {
      title: t("received"),
      dataIndex: "act_received",
      width: 85,
      render: (_, record) => <>{record.act_received || "0"}</>,
    },
    {
      title: t("paymentMethod1"),
      dataIndex: "transfer_method_1",
      width: 130,
      render: (_, record) => emptyCell(record.transfer_method_1),
    },

    {
      title: t("paidDate1"),
      dataIndex: "transfer_date_1",
      render: (_, record) =>
        emptyCell(
          record.transfer_date_1
            ? dayjs(record.transfer_date_1).format("DD/MM/YYYY")
            : ""
        ),
    },
    {
      title: t("paymentContent1"),
      dataIndex: "transfer_info_1",
      width: 130,
      render: (_, record) => emptyCell(record.transfer_info_1),
    },

    { title: t("paymentMethod2"), dataIndex: "transfer_method_2", width: 130 },
    {
      title: t("paidDate2"),
      dataIndex: "transfer_date_2",
      render: (_, record) =>
        record.transfer_date_2
          ? dayjs(record.transfer_date_2).format("DD/MM/YYYY")
          : "",
      width: 80,
    },
    { title: t("paymentContent2"), dataIndex: "transfer_info_2", width: 130 },
    {
      title: t("tuitionStatus"),
      dataIndex: "tuition_status",
      width: 125,
      render: (_, record) => emptyCell(record.tuition_status),
    },
    {
      title: t("recordConsultant"),
      dataIndex: "cosultant",
      render: (_, record) => emptyCell(record.cosultant),
    },
    {
      title: t("confirmationMail"),
      dataIndex: "confirm_mail",
      width: 100,
      render: (_, record) => emptyCell(record.confirm_mail),
    },
    {
      title: t("recordStatus"),
      dataIndex: "status",
      width: 75,
      render: (_, record) => emptyCell(record.status),
    },
    {
      title: t("recordCallback"),
      width: 100,
      dataIndex: "callback",
      render: (text) => (text ? dayjs(text).format("HH:mm, DD/MM/YYYY") : ""),
    },
    {
      title: t("recordClassification"),
      dataIndex: "level",
      width: 90,
      render: (_, record) => emptyCell(record.level),
    },
    {
      title: t("recordOccupation"),
      width: 100,
      dataIndex: "occupation",
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: "break-word",
            whiteSpace: "initial",
          }}
        >
          {emptyCell(text)}
        </div>
      ),
    },
    {
      title: t("recordPurpose"),
      width: 100,
      dataIndex: "purpose",
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: "break-word",
            whiteSpace: "initial",
          }}
        >
          {emptyCell(text)}
        </div>
      ),
    },
    { title: t("recordFacility"), width: 100, dataIndex: "facility" },
    {
      title: t("recordNote1"),
      dataIndex: "note",
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: "break-word",
            whiteSpace: "initial",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: t("recordNote2"),
      dataIndex: "note_1",
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: "break-word",
            whiteSpace: "initial",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: t("utm_source"),
      dataIndex: "utm_source",
      width: 120,
    },
    {
      title: t("utm_medium"),
      dataIndex: "utm_medium",
      width: 120,
    },
    {
      title: t("utm_content"),
      dataIndex: "utm_content",
      width: 120,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSubmitFilters = () => {
    setIsModalVisible(false);
    form.resetFields();
    setUpdateTable(!updateTable);
    console.log("query", query);
  };

  const onResetFilers = () => {
    clearAllRegisterTableFilters();
    onSubmitFilters();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTableChange = (pagination) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    updateRegisterTableFilters({
      page: pagination.current,
      limit: pagination.pageSize,
    });
    setWillChangePage(true);
    setUpdateTable(!updateTable);
  };

  const handleFiltersChange = (e) => {
    updateRegisterTableFilters({ [e.target.name]: e.target.value });
  };

  const handleClearFilter = (key) => {
    clearRegisterTableFilter(key);
    onSubmitFilters();
  };

  const contentExport = () => (
    <div>
      <Radio.Group
        defaultChecked={exportOptions.page}
        onChange={(e) => updateExportOptions({ page: e.target.value })}
      >
        <Space direction="vertical">
          <Radio value={"1"}>{t("currentPage")}</Radio>
          <Radio value={"2"}>{t("allPages")}</Radio>
        </Space>
      </Radio.Group>
      <ExportExcel
        loading={loadingExcel}
        filename="Danh sách nhập liệu"
        dataSheets={dataSheets}
      />
    </div>
  );

  const renderTableHeader = () => (
    <>
      <div style={{ marginBottom: 16 }} className="table-header">
        <div className="left">
          {hasSelected && (
            <div className="delete-zone">
              {!userInfo?.user.role.includes("accountant") && (
                <>
                  <Button
                    className="delete-btn"
                    type="default"
                    onClick={handleDelete}
                    disabled={!hasSelected}
                    loading={loading}
                    icon={<DeleteOutlined />}
                  >
                    {t("delete")}
                    <Ripple />
                  </Button>
                  <span style={{ marginLeft: 8, marginRight: "10px" }}>
                    {hasSelected ? (
                      <Text>
                        {selectedRowKeys.length} {t("selected")}{" "}
                      </Text>
                    ) : (
                      ""
                    )}
                  </span>
                </>
              )}
            </div>
          )}
          <div className="filter-zone">
            <Button
              onClick={showModal}
              type="default"
              icon={<ControlOutlined />}
            >
              {t("filter")}
              <Ripple />
            </Button>
            <Modal
              title={t("filterRecordModal")}
              open={isModalVisible}
              onCancel={handleCancel}
              footer={[
                <Button onClick={onResetFilers}>{t("reset")}</Button>,
                <Button onClick={handleCancel}>{t("cancel")}</Button>,
                <Button key="submit" type="primary" onClick={onSubmitFilters}>
                  {t("ok")}
                </Button>,
              ]}
            >
              <Form form={form} layout="vertical" name="form_in_modal">
                <div className="inline-input">
                  <Form.Item name="startDate" label={t("fromDate")}>
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) =>
                        !userInfo?.user.role.includes("admin") &&
                        current.year() < dayjs().year()
                      }
                      format={"DD/MM/YYYY"}
                      defaultValue={
                        startDate && moment(startDate, "DD/MM/YYYY")
                      }
                      onChange={(_, dateString) =>
                        updateRegisterTableFilters({ startDate: dateString })
                      }
                    />
                  </Form.Item>
                  <Form.Item name="endDate" label={t("toDate")}>
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) =>
                        !userInfo?.user.role.includes("admin") &&
                        current.year() < dayjs().year()
                      }
                      format={"DD/MM/YYYY"}
                      defaultValue={endDate && moment(endDate, "DD/MM/YYYY")}
                      onChange={(_, dateString) =>
                        updateRegisterTableFilters({ endDate: dateString })
                      }
                    />
                  </Form.Item>
                </div>
                <div className="inline-input">
                  <Form.Item label={t("recordDateOfBirthday")}>
                    <Input
                      allowClear
                      defaultValue={yearOfBirth}
                      value={yearOfBirth}
                      name="yearOfBirth"
                      onChange={(e) => handleFiltersChange(e)}
                    />
                  </Form.Item>
                  <Form.Item label={t("recordPhone")}>
                    <Input
                      allowClear
                      defaultValue={phone}
                      value={phone}
                      name="phone"
                      onChange={(e) => handleFiltersChange(e)}
                    />
                  </Form.Item>
                </div>
                <div className="inline-input">
                  <div id="popup-study_status">
                    <Form.Item label={t("studyStatus")}>
                      <Select
                        allowClear
                        defaultValue={studyStatus}
                        value={studyStatus}
                        onChange={(value) =>
                          updateRegisterTableFilters({ studyStatus: value })
                        }
                        placeholder={t("typeToSearch")}
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        {studyStatusEnum.map((status) => (
                          <Option value={status}>{status}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div id="popup-satus">
                    <Form.Item name="status" label={t("recordStatus")}>
                      <Select
                        placeholder={t("typeToSearch")}
                        showSearch
                        allowClear
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        defaultValue={status}
                        value={status}
                        onChange={(value) =>
                          updateRegisterTableFilters({ status: value })
                        }
                      >
                        <Option value="">Không chọn</Option>
                        {statusRecord.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="inline-input">
                  <Form.Item label={t("recordName")}>
                    <Input
                      allowClear
                      defaultValue={name}
                      value={name}
                      name="name"
                      onChange={(e) => handleFiltersChange(e)}
                    />
                  </Form.Item>
                  <div id="popup-address">
                    <Form.Item name="address" label={t("recordAddress")}>
                      <AutoComplete
                        allowClear
                        defaultValue={address}
                        value={address}
                        onChange={(value) =>
                          updateRegisterTableFilters({ address: value })
                        }
                        options={locations}
                        placeholder={t("typeToSearch")}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="inline-input">
                  <div id="popup-expcls">
                    <Form.Item
                      name="expectedClass"
                      label={t("recordExpectedClass")}
                    >
                      <Select
                        placeholder={t("typeToSearch")}
                        showSearch
                        allowClear
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        defaultValue={expectedClass}
                        value={expectedClass}
                        onChange={(value) =>
                          updateRegisterTableFilters({ expectedClass: value })
                        }
                      >
                        <Option value="Chưa chọn">Chưa chọn</Option>
                        {classes?.map((option) => (
                          <Option key={option.value} value={option.title}>
                            {option.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  {(userInfo?.user.role.includes("admin") ||
                    userInfo?.user.role.includes("sales")) && (
                    <div id="popup-channel">
                      <Form.Item name="channel" label={t("recordChannel")}>
                        <AutoComplete
                          showSearch
                          allowClear
                          defaultValue={channel}
                          value={channel}
                          onChange={(value) =>
                            updateRegisterTableFilters({ channel: value })
                          }
                          options={channels}
                          fieldNames={{
                            value: "title",
                            label: "title",
                          }}
                          placeholder={t("typeToSearch")}
                          filterOption={(inputValue, option) =>
                            option.title
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
                <div className="inline-input">
                  <div id="popup-col">
                    <Form.Item name="cosultant" label={t("consultant")}>
                      <Select
                        placeholder={t("typeToSearch")}
                        showSearch
                        allowClear
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        defaultValue={cosultant}
                        value={cosultant}
                        onChange={(value) =>
                          updateRegisterTableFilters({ cosultant: value })
                        }
                      >
                        <Option value="">Không chọn</Option>
                        {(!!ranking ? ranking : cosultants)?.map((item) => (
                          <Option key={item._id} value={item.fullname}>
                            {item.fullname}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div id="popup-tuition">
                    <Form.Item name="tuition_status" label={t("tuitionStatus")}>
                      <Select
                        allowClear
                        defaultValue={tuitionStatus}
                        value={tuitionStatus}
                        onChange={(value) =>
                          updateRegisterTableFilters({ tuitionStatus: value })
                        }
                      >
                        <Option value="Đã hoàn thành">Đã hoàn thành</Option>
                        <Option value="Còn nợ">Còn nợ</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="inline-input">
                  <div id="popup-type-customer">
                    <Form.Item name="type_customer" label={t("customerType")}>
                      <Select
                        allowClear
                        defaultValue={cusomerType}
                        value={cusomerType}
                        onChange={(value) =>
                          updateRegisterTableFilters({ cusomerType: value })
                        }
                      >
                        <Option value="standard">
                          {t("standardCustomer")}
                        </Option>
                        <Option value="combo">{t("comboCustomer")}</Option>
                        <Option value="level up">{t("levelUpCustomer")}</Option>
                        <Option value="L1L2">{t("L1L2")}</Option>
                        <Option value="L2L3">{t("L2L3")}</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div id="popup-level">
                    <Form.Item name="level" label={t("recordClassification")}>
                      <Select
                        allowClear
                        defaultValue={level}
                        value={level}
                        onChange={(value) =>
                          updateRegisterTableFilters({ level: value })
                        }
                      >
                        <Option value="">Không chọn</Option>
                        {LEVEL_ENUM.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="inline-input">
                  <div id="popup-facility">
                    <Form.Item name="facility" label={t("recordFacility")}>
                      <Select
                        allowClear
                        defaultValue={facility}
                        value={facility}
                        onChange={(value) =>
                          updateRegisterTableFilters({ facility: value })
                        }
                        optionLabelProp="label"
                      >
                        {Object.keys(FACILITY_MAPPING)?.map((item, index) => (
                          <Option key={index} value={item}>
                            <div
                              style={{
                                whiteSpace: "wrap",
                              }}
                            >
                              {FACILITY_MAPPING[item].address}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item label={t("recordEmail")}>
                    <Input
                      allowClear
                      defaultValue={email}
                      value={email}
                      name="email"
                      onChange={(e) => handleFiltersChange(e)}
                    />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          </div>
          <div className="source-zone">
            <Text>{t("dataSource")}: </Text>
            <Select
              style={{ width: 120, marginLeft: 5 }}
              defaultValue={source}
              value={source}
              onChange={(value) => {
                updateRegisterTableFilters({ source: value });
                onSubmitFilters();
              }}
            >
              <Option value="">{t("all")}</Option>
              <Option value="1">THALIC EDU</Option>
              <Option value="2">CRM</Option>
              <Option value="3">PANCAKE</Option>
            </Select>
          </div>
          <Popover
            placement="bottomRight"
            title={t("STATUS - LEVEL")}
            content={<LevelStudyStatusList isShowStatusGuide={true} />}
            trigger="click"
          >
            <div className="source-zone">
              <div className="random-box-container">
                <RandomColorBox />
                <QuestionCircleOutlined />
              </div>
            </div>
          </Popover>
        </div>
        <div className="right">
          <Popover
            placement="bottomRight"
            title={t("exportData")}
            content={contentExport}
            trigger="click"
          >
            <Button type="primary" icon={<ExportOutlined />}>
              {t("exportExcel")}
            </Button>
          </Popover>
        </div>
      </div>
    </>
  );

  const renderTableFilterTags = () => (
    <div style={{ marginBottom: 10, display: "flex", flexWrap: "wrap" }}>
      {Object.entries(getFilterTags(filters)).map(([key, valueObj]) => {
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {valueObj.value ? (
              <Tag
                closable
                onClose={() => handleClearFilter(key)}
                style={{ marginBottom: 10 }}
              >
                {t(valueObj.transformedKey)}: {t(valueObj.value)}
              </Tag>
            ) : null}
          </div>
        );
      })}
    </div>
  );

  // if (isFetching) return <Loader fullHeight />;

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <AnimatePresence>
      <motion.div>
        <div className="input-table">
          <>
            {renderTableHeader()}
            {renderTableFilterTags()}
            <Text
              className="flex items-center"
              style={{
                marginBottom: 5,
              }}
            >
              <InfoCircleOutlined
                style={{
                  marginRight: 5,
                }}
              />
              <small
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                <b>{t("recordTableHint")}</b>
                <br />
                <b>Dữ liệu tự động cập nhật sau mỗi 1 phút</b>
              </small>
            </Text>
            <Table
              showHeader={dataSource?.data.length > 0}
              pagination={{
                current: page,
                pageSize: limit,
                total: dataSource?.total,
              }}
              onChange={handleTableChange}
              bordered
              rowSelection={
                userInfo?.user?.role.includes("admin") ? rowSelection : ""
              }
              loading={isFetching}
              columns={columns}
              dataSource={dataSource?.data}
              components={{
                body: {
                  row: CustomRow,
                },
              }}
              onRow={(record) => ({
                tooltip: getTableRowTooltipRegisterClassTable(record),
              })}
              rowClassName={(record) =>
                getTableRowClassNameRegisterClassTable(record)
              }
              scroll={{ x: "max-content" }}
              sticky
            />
            <UpdateForm
              visible={visibleUpdate}
              onUpdate={onUpdate}
              onCancel={() => {
                setVisibleUpdate(false);
              }}
              updateItem={updateItem}
              loading={loadingUpdate}
            />
            <Modal
              className="modal-invoice"
              centered
              title={
                <div className="flex items-center">
                  <Avatar
                    icon={<UserOutlined />}
                    size={width >= 640 ? "large" : "default"}
                  />
                  <div
                    className="flex items-start flex-wrap"
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <div>
                      {updateItem?.name}
                      <>
                        {updateItem?.email && (
                          <div
                            style={{
                              color: "#00000073",
                              fontSize: 14,
                            }}
                          >
                            {updateItem.email}
                          </div>
                        )}
                      </>
                    </div>
                    <div
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {isSentBill === 1 ? (
                        <Tag style={{ margin: 0 }} color="#16c784">
                          {t("sent")}
                        </Tag>
                      ) : isSentBill === 0 ? (
                        <Tag style={{ margin: 0 }} color="#ea3943">
                          {t("notSent")}
                        </Tag>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              }
              open={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
            >
              <div className="bill">
                <Row gutter={8}>
                  <Col xs={24} lg={20}>
                    <div>
                      <BillDetails
                        updateItem={updateItem}
                        isSentBill={isSentBill}
                        showStatus
                      />
                    </div>
                  </Col>
                  <Col xs={24} lg={4}>
                    <Button
                      type="primary"
                      icon={<SendOutlined />}
                      loading={loadingMailBill}
                      disabled={!validateEmail(updateItem?.email) || isSafari}
                      style={{
                        textAlign: "center",
                        marginRight: "10px",
                        width: "100%",
                      }}
                      onClick={() => setOpenEmailDrawer(true)}
                    >
                      {loadingMailBill ? t("sending") : t("previewBeforeSend")}
                    </Button>
                    {isSafari ? (
                      <Alert
                        style={{ marginTop: 20 }}
                        type="error"
                        message={t(
                          "Safari hiện không được hỗ trợ gửi email, hãy thử sang trình duyệt khác như Chrome, Edge,..."
                        )}
                      />
                    ) : (
                      <>
                        {!validateEmail(updateItem?.email) ? (
                          <Alert
                            style={{ marginTop: 20 }}
                            type="error"
                            message={t("invalidNotFoundEmail")}
                          />
                        ) : (
                          <Alert
                            style={{ marginTop: 20 }}
                            type="success"
                            message={t("sendMailNote")}
                          />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Modal>
            <SendEmailDrawer
              open={openEmailDrawer}
              onClose={() => setOpenEmailDrawer(false)}
              updateItem={updateItem}
              teachers={teachers}
              assistants={assistants}
              loadingMailBill={loadingMailBill}
              setLoadingMailBill={setLoadingMailBill}
              setIsSentBill={setIsSentBill}
              updateTable={updateTable}
              setUpdateTable={setUpdateTable}
            />
          </>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default RegisterTable;
